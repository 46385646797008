<template>
  <h1 class="leading-none lg:mb-8">About the clean</h1>
  <section>
    <FormField v-slot="{ componentField }" name="hours">
      <FormItem v-auto-animate>
        <FormLabel class="lg:mb-12">
          How many hours will the cleaner be required?
        </FormLabel>
        <FormControl>
          <ToggleGroup
            v-bind="componentField"
            type="single"
            class="radio-buttons lg:grid-cols-4 grid-cols-2"
            v-model="form.hours"
          >
            <template
              v-for="hour in [2, 2.5, 3, 3.5, 4, 4.5, 5].filter(
                (i) => i >= getMinimumHours(form.service!)
              )"
            >
              <template v-if="hour === 3">
                <ToggleGroupItem
                  :class="{
                    active: form.hours === 3,
                  }"
                  value="3"
                  class="lg:-mt-8 lg:h-24 h-24 relative lg:col-span-1 col-span-2"
                >
                  <div
                    class="absolute top-0 text-sm leading-3 left-0 w-full bg-primary/5 rounded-t-lg py-2 uppercase font-mono tracking-widest"
                  >
                    popular
                  </div>
                  <div class="pt-8">3 hours</div>
                </ToggleGroupItem>
              </template>
              <template v-else>
                <ToggleGroupItem
                  :class="{
                    active: form.hours === hour,
                  }"
                  :value="hour.toString()"
                >
                  {{ hour }} hours
                </ToggleGroupItem>
              </template>
            </template>
          </ToggleGroup>
        </FormControl>
        <FormMessage />
      </FormItem>
    </FormField>
  </section>
  <hr />
  <section v-if="isRegular()">
    <FormField v-slot="{ componentField }" name="preferred_days">
      <FormItem v-auto-animate>
        <FormLabel> Which days would be best suited for you? </FormLabel>
				<FormDescription>If you are flexible with the day and time of your booking select <b><em>Any Day</em></b> and we might be able to find you a cleaner sooner!</FormDescription>
        <FormControl>
          <ToggleGroup
            :type="'multiple'"
            class="radio-buttons mt-12 lg:grid-cols-4 md:grid-cols-3 grid-cols-2"
            v-bind="componentField"
            v-model="form.preferred_days"
          >
            <ToggleGroupItem
              v-for="day in [
                { value: 'Mon', label: 'Monday' },
                { value: 'Tue', label: 'Tuesday' },
                { value: 'Wed', label: 'Wednesday' },
                { value: 'Thu', label: 'Thursday' },
                { value: 'Fri', label: 'Friday' },
                { value: 'Sat', label: 'Saturday' },
                { value: 'Any Day', label: 'Any Day' },
              ]"
              :value="day.value"
              :class="{
                active: form.preferred_days?.includes(day.value),
              }"
            >
              {{ day.label }}
            </ToggleGroupItem>
          </ToggleGroup>
        </FormControl>
        <FormMessage />
      </FormItem>
    </FormField>
  </section>
  <hr />
  <section>
    <FormField v-slot="{ componentField }" name="start_date">
      <FormItem v-auto-animate>
        <FormLabel> When would you like you clean the start? </FormLabel>
        <FormControl>
          <Popover>
            <PopoverTrigger as-child>
              <Button
                id="start_date_trigger"
                :variant="'outline'"
                :class="
                  cn(
                    'w-full justify-start text-left border-input font-normal py-[23px] lg:py-[31px] hover:bg-primary-light hover:border-primary-soft focus:border-primary',
                    !form.start_date && 'text-muted-foreground',
                    form.start_date && 'bg-primary-light border-primary-soft'
                  )
                "
              >
                <CalendarIcon class="mr-2 h-4 w-4" />
                <span>{{
                  form.start_date
                    ? dayjs(form.start_date).format("dddd, MMMM D, YYYY")
                    : "Pick a date"
                }}</span>
              </Button>
            </PopoverTrigger>
            <PopoverContent class="w-auto p-0">
              <Calendar
                v-bind="componentField"
                :disabled-dates="disabledDates"
                :min-date="minDate"
                v-model="form.start_date"
              />
            </PopoverContent>
          </Popover>
        </FormControl>
        <FormMessage />
      </FormItem>
    </FormField>
  </section>
  <hr />
  <section>
    <FormField v-slot="{ componentField }" name="message">
      <FormItem v-auto-animate>
        <FormLabel> Special Requirements </FormLabel>
        <FormDescription
          >Please be aware these comments will be shown to
          cleaners.</FormDescription
        >
        <FormControl>
          <Textarea
            v-bind="componentField"
            v-model="form.message"
            placeholder="Write your message."
            rows="6"
            class="mt-4"
          ></Textarea>
        </FormControl>
      </FormItem>
    </FormField>
  </section>
</template>

<script setup lang="ts">
import dayjs from "dayjs";
import { ToggleGroup, ToggleGroupItem } from "@/components/ui/toggle-group";
import { Textarea } from "@/components/ui/textarea";
import { useBookingStore } from "~/store";
import { Calendar as CalendarIcon } from "lucide-vue-next";
import { cn } from "@/lib/utils";
import { Button } from "@/components/ui/button";
import { Calendar } from "@/components/ui/calendar";
import { vAutoAnimate } from "@formkit/auto-animate/vue";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";

const { form, isOneoff, isRegular, getMinimumHours } = useBookingStore();

const minDate = dayjs().add(1, "day").startOf("day").toDate();

const disabledDates = computed(() => {
  const now = dayjs();
  const currentDay = now.day();
  const currentTime = now.hour();
  const dates: {
    start?: Date;
    end?: Date;
    repeat?: {
      weekdays: number[];
    };
  }[] = [];

  /* Disable all Sundays */
  dates.push({ repeat: { weekdays: [1] } });

  /* Disable the coming Monday if it's Friday after 2pm or during the weekend */
  if ((currentDay === 5 && currentTime >= 14) || [0, 6].includes(currentDay)) {
    const nextMonday = dayjs().add(1, "week").day(1).startOf("day").toDate();
    dates.push({ start: nextMonday, end: nextMonday });
  }

  /* Disable the current day and the following day after 2pm */
  if (currentTime >= 14) {
    const today = now.startOf("day").toDate();
    const tomorrow = now.add(1, "day").startOf("day").toDate();
    dates.push({ start: today, end: today });
    dates.push({ start: tomorrow, end: tomorrow });
  }

  if (isOneoff()) {
    const today = now.startOf("day").toDate();
    dates.push({ start: today, end: today });
  }

  return dates;
});
</script>
